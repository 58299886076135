<template>
  <div>
    <div class="row home_game_arrow home_game_lotto" v-if="show_id == 'lotto'">
      <div class="d-flex justify-content-between">
        <div class="game_logo_img fw-bolder my-3">
          {{ $t("text_lotto").toUpperCase() }}
        </div>
        <div class="top_arrow mb-0">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('lotto', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('lotto', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'lobby', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/1.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >Lotto Lobby</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'TOTOMAS@', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/2.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >TOTO Lotto</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', '4DSGC@', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/3.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >Singapore 4D</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'DMCMAS@', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/4.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >Malaysia Lotto</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'THAIGOV', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/5.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >Thailand Lotto</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'TCGTURBO3D', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/6.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >TCG 3D</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'DJSTOCK', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/7.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >Dow Jones Lotto</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'NASTOCK', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/8.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >NASDAQ Lotto</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'HKSTOCK', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/9.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >Hong Kong Lotto</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'TCGSPDPK10', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/10.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >TCGPK10</span
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              class="game_item"
              @click="game('', '', 'HKSTOCK', 'TCG', 'lottery')"
            >
              <img src="@/assets/img/game/lottery/11.png" class="w-100" />
              <span class="position-absolute start-0 bottom-0 text-center w-100"
                >Bitcoin FFC</span
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div
      class="row home_game_arrow home_game_casino"
      v-if="show_id == 'casino'"
    >
      <div class="d-flex justify-content-between">
        <div class="game_logo_img fw-bolder my-3">
          {{ $t("home_text6").toUpperCase() }}
        </div>
        <div class="top_arrow mb-0">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('casino', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('casino', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '', 'BG', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_bg.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >BG</span
                                >
            </div>
          </div>
        </swiper-slide> -->
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '', 'YB', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_yeebet.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >YB</span
                                >
            </div>
          </div>
        </swiper-slide> -->
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '0', 'WM', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_wm.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >WM</span
                                >
            </div>
          </div>
        </swiper-slide> -->
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '', 'EVO', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_evo.png" class="w-100" />
              <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >EVO</span
                                > -->
            </div>
          </div>
        </swiper-slide>
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', 'xg006', 'XG', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_xg.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >XG</span
                                >
            </div>
          </div>
        </swiper-slide> -->
        <!-- <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('', '1', '0', 'AG', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_ag.png" class="w-100" />
              <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >AG</span
                                >
            </div>
          </div>
        </swiper-slide> -->
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('web', 'casino', '101', 'PP', 'casino')"
              class="game_item"
            >
              <img src="@/assets/img/game/all/live_pp.png" class="w-100" />
              <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >PP</span
                                > -->
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="row home_game_arrow home_game_sport" v-if="show_id == 'sport'">
      <div class="d-flex justify-content-between">
        <div class="game_logo_img fw-bolder my-3">
          {{ $t("text_sport").toUpperCase() }}
        </div>
        <div class="top_arrow mb-0">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('sport', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('sport', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=3',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_PREMIER.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=2;sportid=2;leaguekey=56038',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_NBA.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=43',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_LALIGA.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=8',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_LIGUE1.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=5',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img
                src="@/assets/img/sport/sport_BUNDESLIGA.png"
                class="w-100"
              />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=4',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_SERIEA.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=8;sportid=8;leaguekey=127094',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_MLB.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=3;sportid=3;leaguekey=127105',
                  'SB',
                  'sport'
                )
              "
              class="game_item"
            >
              <img src="@/assets/img/sport/sport_NFL.png" class="w-100" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="show_id == 'slot_pp'">
      <div
        class="row home_game_arrow"
        :class="'home_game_slot_' + j"
        v-for="j in slot_game"
        :key="j"
      >
        <div :class="j.toLowerCase() == 'pg' ? 'g-flex-wrap' : ''">
          <div class="d-flex justify-content-between">
            <div class="game_logo_img fw-bolder my-3">
              <img
                :src="require('@/assets/img/game/' + j + '.png')"
                :title="j.toUpperCase()"
                style="width: 55px; height: 25px"
              />&nbsp;{{ j.toUpperCase() }}
            </div>
            <div
              class="top_arrow mb-0"
              :class="j.toLowerCase() == 'pg' ? 'd-none' : ''"
            >
              <div class="left_right">
                <button class="btn_arrow" @click="turn('slot_' + j, 'left')">
                  <span role="img" aria-label="left"
                    ><svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="left"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                      ></path>
                    </svg>
                  </span></button
                ><button class="btn_arrow" @click="turn('slot_' + j, 'right')">
                  <span role="img" aria-label="right"
                    ><svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="right"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <swiper
            :loop="swiper_casino_options.loop"
            :speed="swiper_casino_options.speed"
            :slidesPerView="swiper_casino_options.slidesPerView"
            :spaceBetween="swiper_casino_options.spaceBetween"
            :breakpoints="swiper_casino_options.breakpoints"
            :navigation="swiper_casino_options.navigation"
            :class="j.toLowerCase() == 'pg' ? 'swiper-no-swiping' : ''"
          >
            <swiper-slide
              v-for="l in pp_list(j)"
              :key="l"
              class="home_game_img"
            >
              <div class="home_game_img_div">
                <div
                  :class="css_list"
                  @click="
                    game(l.device, l.mode, l.gameid, l.gametype, l.gamekind)
                  "
                  class="game_item"
                >
                  <img
                    v-lazy="'http://gameweb.xkzkji.com/' + l.image"
                    class="w-100"
                  />
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div
      class="row home_game_arrow home_game_casino_evo"
      v-if="show_id == 'casino_evo'"
    >
      <div class="d-flex justify-content-between">
        <div class="game_logo_img fw-bolder my-3">
          {{ $t("home_text6").toUpperCase() }}
        </div>
        <div class="top_arrow mb-0">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('casino_evo', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('casino_evo', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide
          class="home_game_img"
          v-for="l in evocasino_list"
          :key="l"
        >
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game(l.device, l.mode, l.gameid, l.gametype, l.gamekind)"
              class="game_item"
            >
              <img
                v-lazy="'http://gameweb.xkzkji.com/' + l.image"
                class="w-100"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { reactive } from "vue";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
import newWindow from "./iframe.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: { show_id: String },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    newWindow,
  },
  data() {
    return {
      code: Cookies.get("code"),
      css_list: "position-relative",
      newWindowurl: "",
      slot_game: ["pg", "pp", "fachai"], //, "jili"
    };
  },
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      let param = {
        action: "GameLobby",
        body: {
          gametype: t,
          type: "all",
          uid: localStorage.getItem("uid"),
        },
      };
      this.getGameList(param);
    },
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (type.toUpperCase() == "TCG" || type.toUpperCase() == "SB") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
  watch: {
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$emit("watch_newWindowurl", "close");
      }
    },
  },
  computed: {
    ...mapGetters(["list"]),
    evocasino_list: function () {
      console.log(this.list);
      return JSON.parse(localStorage.getItem("evopremium_all_gamelist"));
    },
    pp_list: function () {
      console.log(this.list);
      return function (jj) {
        if (
          JSON.parse(
            localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
          ) != null &&
          JSON.parse(
            localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
          ) != ""
        )
          return JSON.parse(
            localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
          ).slice(0, 15);
        else return [];
      };
    },
  },
  mounted() {},
  created() {
    if (this.show_id == "slot_pp") {
      this.gamelist("PP");
      this.gamelist("PG");
      // this.gamelist("JILI");
      this.gamelist("FACHAI");
    }
    if (this.show_id == "casino_evo") {
      this.gamelist("EVOPREMIUM");
    }
  },
};
</script>
