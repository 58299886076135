<template>
  <swiper
    :autoplay="swiper_options.autoplay"
    :loop="swiper_options.loop"
    :speed="swiper_options.speed"
    :slidesPerView="swiper_options.slidesPerView"
    :spaceBetween="swiper_options.spaceBetween"
    :breakpoints="swiper_options.breakpoints"
    :pagination="{ clickable: true }"
    class="main_banner"
  >
    <swiper-slide v-for="l in list" :key="l">
      <img v-lazy="l" />
    </swiper-slide>
  </swiper>
</template>
<script>
import { reactive } from "vue";
//引入swiper
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);
export default {
  name: "banner",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return { list: null };
  },
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      speed: 1000,
      slidesPerView: 3,
      spaceBetween: 15,
      pagination: {
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
        },
        // when window width is >= 1200px
        1200: {
          slidesPerView: 3,
        },
      },
    });

    return {
      swiper_options,
    };
  },
  methods: {
    bannerlist: function () {
      let param = {
        action: "banner",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res["urlList"];
      });
    },
  },
  mounted() {
    this.bannerlist();
  },
};
</script>
