<template>
  <div>
    <Loading :active="isLoading" :can-cancel="true">
      <img
        src="@/assets/img/main/35771931234507.564a1d2403b3a.gif"
        class="w-100"
      />
    </Loading>
    <router-view />
    <pop></pop>
    <ann></ann>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import pop from "./views/Home/pop.vue";
import ann from "./views/Home/announce.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  created() {
    document.title = "Riqueza777 | " + this.$t("text_web_title").toUpperCase();
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  components: {
    Loading,
    pop,
    ann,
  },
};
</script>
