<template>
  <div class="row home_game_arrow home_game_slot">
    <div class="d-flex justify-content-between">
      <div class="game_logo_img fw-bolder my-3">
        {{ $t("home_text7").toUpperCase() }}
      </div>
      <div class="top_arrow mb-0">
        <div class="left_right">
          <button class="btn_arrow" @click="turn('left')">
            <span role="img" aria-label="left"
              ><svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="left"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                ></path>
              </svg>
            </span></button
          ><button class="btn_arrow" @click="turn('right')">
            <span role="img" aria-label="right"
              ><svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="right"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
    <swiper
      :loop="swiper_options.loop"
      :speed="swiper_options.speed"
      :slidesPerView="swiper_options.slidesPerView"
      :spaceBetween="swiper_options.spaceBetween"
      :breakpoints="swiper_options.breakpoints"
      :navigation="swiper_options.navigation"
    >
      <swiper-slide v-for="l in slot_game" :key="l" class="home_game_img">
        <div class="home_game_img_div">
          <div :class="css_list" @click="set_gametype(l.toUpperCase())" class="game_item">
            <img
              :src="require('@/assets/img/game/all/slot_' + l + '.png')"
              class="w-100"
            />
            <!-- <span
              class="position-absolute start-0 bottom-0 text-center w-100"
              >{{ l.toUpperCase() }}</span
            > -->
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { reactive } from "vue";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
export default {
  props: {},
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      css_list: "position-relative",
      slot_game: [
        "pg",
        "fachai",
        // "jili",
        "ps",
        "ka",
        "funta",
        // "rich",
        "pp",
        // "CQ9",
      ],
    };
  },
  methods: {
    turn(e) {
      if (e == "left") {
        document.querySelector(".home_game_slot .swiper-button-prev").click();
      } else {
        document.querySelector(".home_game_slot .swiper-button-next").click();
      }
    },
    set_gametype: function (e) {
      localStorage.setItem("g", e);
      this.$router.push("/slot");
    },
  },
  computed: {},
  mounted() {},
  created() {},
};
</script>
